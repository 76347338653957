<template>
  <div class="login">
    <div class="loginImg">
      <div class="imgBox">
        <img src="../../assets/img/loginImg2.png" alt="">
        <p>技能学习系统</p>
      </div>
    </div>
    <div style="padding:20px">
      <van-field v-model="userName" label="账号" left-icon="contact" placeholder="请输入账号" />
      <van-field v-model="passWord" label="密码" left-icon="lock" type="password" placeholder="请输入密码" />
      <div class="loginBtn" @click="lgoin">登录</div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
export default {
  data() {
    return {
      userName: "",
      passWord: "",
      studentID: "",
      teacherID: "",
    }
  },
  methods: {
    // 点击登录按钮
    lgoin() {
      let para = {
        LoginName: this.userName,
        LoginPassWord: this.passWord
      };
      this.$axios.post('/api/User/Login', para).then(res => {
        var data = JSON.parse(res.data);
        if (data.code == 200) {
          console.log(data.data);

          if (data.data.Type == "1") {
            Cookies.set('studentID', data.data.ID, { expires: 5, path: '' });
            Cookies.set('studentName', data.data.NickName, { expires: 5, path: '' });
            this.$router.push({ name: "home" });
          }
          else {
            Cookies.set('teacherID', data.data.ID, { expires: 5, path: '' });
            Cookies.set('teacherName', data.data.RealName, { expires: 5, path: '' });
            this.$router.push({ name: "teacherhome" });
          }
        }
        else {
          this.$dialog({ message: "登录失败，账号或密码不对" });
        }
      });
    }
  },
  mounted() {
    this.studentID = Cookies.get('studentID');
    this.teacherID = Cookies.get('teacherID');
    console.log(this.studentID);
  },
}
</script>

<style>
.login {
  width: 100%;
  height: 100vh;
}
.login .loginImg {
  width: 100%;
  height: 40vh;
  background-image: url("../../assets/img/loginImg.png");
  display: flex;
  align-items: center;
}
.login .loginImg .imgBox {
  margin: 0 auto;
  text-align: center;
}
.login .loginImg img {
  width: 80px;
  height: 80px;
}
.login .loginImg .imgBox p {
  font-size: 24px;
  font-weight: bold;
  margin-top: 24px;
  color: #fff;
}
.login .van-cell {
  border-radius: 20px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}
.login .loginBtn {
  width: 100%;
  height: 48px;
  line-height: 48px;
  text-align: center;
  color: #fff;
  background: #3294ff;
  border-radius: 24px;
}
</style>